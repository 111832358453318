import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import SignUpBanner from 'gatsby-theme-husky/src/components/SignUpBanner';
import ProductDetails from 'gatsby-theme-husky/src/components/ProductDetails/ProductDetails';
import RelatedProducts from 'gatsby-theme-husky/src/components/RelatedProducts';
import ProductsCategories from 'gatsby-theme-husky/src/components/ProductsList/ProductsCategories';
import Storytap from 'gatsby-theme-husky/src/components/Storytap';
import VideoList from 'gatsby-theme-husky/src/components/VideoList';
import PriceSpider from 'gatsby-theme-husky/src/common/PriceSpider';
import BazaarVoice from 'gatsby-theme-husky/src/common/BazaarVoice';

import { getLanguage, toBoolean } from 'gatsby-theme-husky/src/utils/stringUtils/stringUtils';
import trackCatalogUpdate from 'gatsby-theme-husky/src/utils/externalServices/bazaarVoice';
import { getLangString, isExists, scrollToRef } from 'gatsby-theme-husky/src/utils/functions';
import { CAROUSEL_DEFAULT_SETTINGS } from 'gatsby-theme-husky/src/utils/constants';

import { Row } from 'gatsby-theme-husky/src/layout';
import Layout from 'gatsby-theme-husky/src/layout/Layout';
import Banner from 'gatsby-theme-husky/src/common/Banner';
import Seo from 'gatsby-theme-husky/src/common/Seo';
import PageSchema from 'gatsby-theme-husky/src/common/PageSchema';

import './ProductDetailPageOverride.scss';

const ProductDetailsPage = ({
  data: {
    allSalsifyProducts: { nodes: salsifyProductsNodes },
    allUmbracoProductsList: { nodes },
    productsListCategories: { productsCategories },
    relatedProductsList: { relatedProductsNodes },
  },
}) => {
  const {
    langProps: { urls, lang },
    seoProps: { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs },
    productTitle,
    productTitleWithSize,
    tags: [{ name: tagName }],
    localProductImage,
    productImage,
    productFeature1,
    productFeature2,
    productFeature3,
    productFeature4,
    productFeature5,
    productFeature6,
    productFeature7,
    productFeature8,
    productDescriptionLong,
    productDisclaimers,
    productEAN,
    storytap,
    relatedProducts: { relatedProductsTitle },
    url,
    bazaarVoiceReviews,
    closeModalAriaLabel,
    productContent,
    dropdownButton,
    shops,
    productTitleShort,
    ingredients,
    directionsForUse,
    safetyWarnings,
    productInfo,
    productFeature,
    relatedVideos,
    relatedVideosTitle,
    relatedVideosPlayText,
  } = salsifyProductsNodes[0];
  const {
    productsMainBanner: { content, bannerVariant, imageAsBackground, imageAsBackgroundAlt },
  } = nodes[0];

  const { currentCategory } = nodes.filter(({ variant }) => variant === tagName)[0];
  const { disableStorytap, id, eid } = storytap || {};
  const reviewsTitle = bazaarVoiceReviews?.reviewsTitle;

  const product = {
    productImage,
    productId: productEAN,
    productName: productTitle,
    url,
    category: [{ Name: tagName }],
  };

  useEffect(() => {
    if (window.BV) {
      trackCatalogUpdate([product], getLangString(lang));
    }
  }, [product]);

  const [bazaarVoice, setBazaarVoice] = useState(
    process.env.GATSBY_BAZAAR_VOICE_URL
      ? process.env.GATSBY_BAZAAR_VOICE_URL.replace('{lang}', getLangString(lang))
      : ''
  );

  useEffect(() => {
    process.env.GATSBY_BAZAAR_VOICE_URL &&
      setBazaarVoice(process.env.GATSBY_BAZAAR_VOICE_URL!.replace('{lang}', getLangString(lang)));
  }, [lang]);

  const reviewsRef = useRef<HTMLDivElement>(null);

  const NextArrow = <button type="button">&gt;</button>;

  const relatedVideosSettings = {
    numberOfVideos: 10,
    playVideoText: relatedVideosPlayText,
    sectionTitle: relatedVideosTitle,
  };

  const carouselSettings = {
    ...CAROUSEL_DEFAULT_SETTINGS,
    slidesToShow: 2.3,
    infinite: false,
    arrows: true,
    nextArrow: NextArrow,
  };

  useEffect(() => {
    setTimeout(() => {
      if (isExists(window.location.href, '#reviews_section')) {
        scrollToRef(reviewsRef);
      }
    }, 4000);
  }, []);

  return (
    <Layout langProps={{ urls, lang }} className="product-detail-page">
      <Helmet>
        <script async src={bazaarVoice} type="text/javascript" />
      </Helmet>
      <Seo
        {...{
          title: seoMetaTitle,
          description: seoMetaDescription,
          keywords: seoMetaKeywords,
          externalHreflangs: seoExternalHreflangs,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <PriceSpider lang={lang} />
      <Banner
        {...{
          content,
          bannerVariant,
          imageAsBackground,
          imageAsBackgroundAlt,
        }}
      />
      <div id="product-image" />
      <div className="products-list" data-testid="products-list">
        <ProductsCategories {...{ productsCategories, currentCategory }} />
      </div>
      <ProductDetails
        {...{
          productTitle,
          productTitleWithSize,
          localProductImage,
          productImage,
          productFeature1,
          productFeature2,
          productFeature3,
          productFeature4,
          productFeature5,
          productFeature6,
          productFeature7,
          productFeature8,
          productDescriptionLong,
          productDisclaimers,
          productEAN,
          closeModalAriaLabel,
          productContent,
          dropdownButton,
          shops,
          productTitleShort,
          ingredients,
          directionsForUse,
          safetyWarnings,
          productInfo,
          productFeature,
        }}
      />
      {toBoolean(disableStorytap) ? null : (
        <Storytap {...{ id, lang: lang === 'en-ca' ? 'en-US' : getLanguage(lang), eid }} />
      )}
      <RelatedProducts {...{ relatedProductsNodes, relatedProductsTitle }} />
      <div ref={reviewsRef} id="reviews_section" />
      <Row className="flex-x-center product__bazaar-reviews">
        <div className="col--md8">
          {productEAN ? (
            <>
              {reviewsTitle ? (
                <div className="reviews__section-title">
                  <span>{reviewsTitle}</span>
                </div>
              ) : null}
              <BazaarVoice eanCode={productEAN} />
            </>
          ) : null}
        </div>
      </Row>
      {relatedVideos ? (
        <VideoList
          videoListSettings={relatedVideosSettings}
          umbracoVideosNodes={relatedVideos}
          withCarousel
          modalClassNameModifier="men"
          {...{ carouselSettings }}
        />
      ) : null}
      <SignUpBanner {...{ lang }} />
    </Layout>
  );
};

export const query = graphql`
  query ProductDetailQueryOverride($lang: String, $sku: String, $relatedProductsValues: [String]) {
    relatedProductsList: allSalsifyProducts(
      filter: { sku: { in: $relatedProductsValues }, langProps: { lang: { eq: $lang } } }
    ) {
      relatedProductsNodes: nodes {
        localProductImage {
          childImageSharp {
            fluid(quality: 10) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        relatedProducts {
          relatedProductsLearnMore
        }
        variantProductCards {
          title
          image
          size
          titleShort
          titleWithSize
          url
          ean
        }
        productTitle
        productDescriptionLong
        productDisclaimers
        productTitleWithSize
        url
      }
    }
    allSalsifyProducts(filter: { sku: { eq: $sku }, langProps: { lang: { eq: $lang } } }) {
      nodes {
        productContent {
          variants {
            variantLink {
              url
            }
            variantText
          }
        }
        dropdownButton {
          text
          ariaLabel
        }
        shops {
          arialabel
          url
          shopImage {
            ...umbracoImage
          }
        }
        productTitle
        productTitleWithSize
        productFeature1
        productFeature2
        productFeature3
        productFeature4
        productFeature5
        productFeature6
        productFeature7
        productFeature8
        ingredients
        directionsForUse
        safetyWarnings
        productTitleShort
        productTitle
        productImage
        productDescriptionLong
        productDisclaimers
        productEAN
        url
        sku
        closeModalAriaLabel
        bazaarVoiceReviews {
          reviewsTitle
        }
        tags {
          name
        }
        relatedProducts {
          relatedProductsTitle
        }
        storytap {
          disableStorytap
          id
          eid
        }
        seoProps {
          seoMetaDescription
          seoMetaKeywords
          seoMetaTitle
          seoExternalHreflangs {
            key
            value
          }
        }
        productImage
        localProductImage {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        langProps {
          lang
          urls {
            ...languageUrls
          }
        }
        productInfo {
          description
          infoType
          title
        }
        productFeature {
          description
          featureType
          title
        }
        relatedVideos {
          youtubeVideo
          title
          description
          videoCoverImage {
            ...umbracoImage
          }
          videoImageAlt
        }
        relatedVideosTitle
        relatedVideosPlayText
      }
    }
    allUmbracoProductsList(filter: { langProps: { lang: { eq: $lang } } }) {
      nodes {
        currentCategory: name
        variant
        productsMainBanner {
          content
          bannerVariant
          imageAsBackgroundAlt
          imageAsBackground {
            fallbackUrl
            fluid {
              srcSet
              originalImg
              base64
            }
            variants {
              url
              type
              fallbackQuery
              alias
            }
          }
        }
      }
    }
    productsListCategories: allUmbracoProductsList(filter: { langProps: { lang: { eq: $lang } } }) {
      productsCategories: nodes {
        ariaLabel
        name
        link
        hideInCategoriesList
      }
    }
  }
`;

export default ProductDetailsPage;
